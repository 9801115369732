@tailwind base;

@layer base {
  img {
    @apply inline-block;
  }
}

@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard/Pretendard-Thin.otf') format('opentype');
  font-weight: 100;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard/Pretendard-ExtraLight.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard/Pretendard-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard/Pretendard-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard/Pretendard-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard/Pretendard-SemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard/Pretendard-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard/Pretendard-ExtraBold.otf') format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./fonts/Pretendard/Pretendard-Black.otf') format('opentype');
  font-weight: 900;
}

body {
  margin: 0;
  font-family: 'Pretendard';
}

.react-datepicker__input-container {
  width: 100%;
  height: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 100%;
  padding-left: 12px;
  padding-right: 12px;
}

.react-datepicker__input-container input:focus-visible {
  outline: none;
}